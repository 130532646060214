"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const Theme_1 = require("../Theme");
const Helper_1 = require("../Helper");
const react_i18next_1 = require("react-i18next");
const styles = react_native_1.StyleSheet.create({
    button: {
        alignItems: 'center',
        backgroundColor: Theme_1.THEME.Secondary,
        borderRadius: 2,
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 8,
        paddingHorizontal: 12,
        paddingTop: 6
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: '500'
    },
    secondaryButton: {
        justifyContent: 'center',
        marginVertical: 8,
        alignItems: 'center',
    },
    secondaryButtonText: {
        color: Theme_1.THEME.Secondary,
        fontSize: 16,
        fontWeight: '500'
    },
});
function Loading(props) {
    const { t } = (0, react_i18next_1.useTranslation)();
    let message = props.message ?? t('common:messages.tryAgain');
    let retryable = props.retryable ?? false;
    let cancelable = props.cancelable ?? false;
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: { alignItems: 'center' }, children: [(props.loading) &&
                (0, jsx_runtime_1.jsx)(react_native_1.ActivityIndicator, { color: Theme_1.THEME.Secondary, size: "large", style: {
                        marginBottom: Helper_1.Helper.isPlatformWeb ? 18 : 21,
                        marginTop: Helper_1.Helper.isPlatformWeb ? 20 : 22
                    } }), (props.error && !props.loading) &&
                (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: { marginBottom: 14 }, children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { fontSize: 18, color: Theme_1.THEME.Text }, children: message }) }), retryable &&
                            (0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: () => props.onRetry(), style: ({ pressed }) => [
                                    styles.button,
                                    pressed ? { opacity: 0.6 } : null
                                ], children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: styles.buttonText, children: t('common:buttons.tryAgain') }) }), cancelable &&
                            (0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: () => props.onCancel(), style: ({ pressed }) => [
                                    styles.secondaryButton,
                                    pressed ? { opacity: 0.6 } : null
                                ], children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: styles.secondaryButtonText, children: t('common:buttons.cancel') }) })] })] }));
}
exports.default = Loading;
;
