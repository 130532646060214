"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const Theme_1 = require("../Theme");
const styles = react_native_1.StyleSheet.create({
    fieldCaption: {
        color: Theme_1.THEME.TextLight,
        fontSize: 12,
        marginBottom: 2,
    },
    fieldValue: {
        color: Theme_1.THEME.Text,
        fontSize: 14,
        marginTop: 2,
    },
});
;
function Field(props) {
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { accessible: true, style: [{ paddingTop: 6, paddingBottom: 6 }, props.style], children: [(0, jsx_runtime_1.jsx)(react_native_1.Text, { accessible: false, style: styles.fieldCaption, children: props.caption }), (0, jsx_runtime_1.jsx)(react_native_1.Text, { accessible: false, style: styles.fieldValue, children: props.value })] }));
}
exports.default = Field;
;
