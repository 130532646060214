"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const react_native_1 = require("react-native");
const Helper_1 = require("../Helper");
const Theme_1 = require("../Theme");
const react_i18next_1 = require("react-i18next");
const UserContext_1 = require("../contexts/UserContext");
function ProgressSteps(props) {
    const userContext = react_1.default.useContext(UserContext_1.UserContext);
    const { t } = (0, react_i18next_1.useTranslation)();
    const getIcon = (stepError, index) => {
        if (props.currentStep == index + 1 && stepError)
            return Theme_1.ICONS.Solid.CircleXmark;
        else if (stepError)
            return Theme_1.ICONS.Regular.CircleXmark;
        else if (props.currentStep == index + 1)
            return Theme_1.ICONS.Solid.Circle;
        else if (props.currentStep > index + 1)
            return Theme_1.ICONS.Solid.CircleCheck;
        else
            return Theme_1.ICONS.Regular.Circle;
    };
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: { backgroundColor: `${Theme_1.THEME.Primary}11`, paddingHorizontal: 12, paddingBottom: 6, paddingTop: userContext.isLandscapeView ? 6 : 0, marginTop: 6, borderRadius: 5 }, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginVertical: 6 }, children: props.stepErrors.map((_item, _index) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: getIcon(_item, _index), size: 18, color: _item ? Theme_1.THEME.Red : Theme_1.THEME.Primary }), _index + 1 != props.stepErrors.length &&
                            (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { flex: 1, borderBottomColor: Theme_1.THEME.Primary, borderBottomWidth: 2 } })] }, _index))) }), props.title &&
                (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 6 }, children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: Theme_1.THEME.Text, fontSize: Helper_1.Helper.isSizeWeb ? 16 : 14, marginLeft: 12 }, children: `${props.currentStep} — ${props.title}` }) })] }));
}
exports.default = ProgressSteps;
