"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
// Based on https://github.com/hyochan/react-native-masonry-list
// Simplified and removed the ScrollView wrapping the component.
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
function MasonryList(props) {
    const { columns = 1, horizontal = false, keyExtractor, listEmpty, renderItem = ({ item }) => item, style } = props;
    let data = props.data || props.children;
    if (!props.renderItem) {
        data = data.filter((i) => react_1.default.isValidElement(i));
    }
    return ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: [
            {
                flex: 1,
                flexDirection: horizontal ? 'column' : 'row',
            },
            style,
        ], children: data?.length === 0
            &&
                (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: listEmpty })
            ||
                Array.from(Array(columns), (_, num) => {
                    return ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: {
                            flex: 1 / columns,
                            flexDirection: horizontal ? 'row' : 'column',
                        }, children: data.map((item, i) => {
                            if (i % columns === num) {
                                return ((0, jsx_runtime_1.jsx)(react_native_1.View, { children: renderItem({ item, i }) }, keyExtractor?.(item, i) || `masonry-${num}-${i}`));
                            }
                            return null;
                        })
                            .filter((e) => !!e) }, `masonry-${num}`));
                }) }));
}
exports.default = (0, react_1.memo)(MasonryList);
