"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onMessageReceived = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
async function onMessageReceived(message) {
}
exports.onMessageReceived = onMessageReceived;
;
function Notification() {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}));
}
exports.default = Notification;
