import { AppRegistry } from 'react-native';
import App from "./src/App";
import AppJson from './app.json';
import "./i18n";
import { en, es, registerTranslation } from "react-native-paper-dates";
import iconFont from "react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf";
import { SingleSignOn } from './src/shared/SingleSignOn';

registerTranslation("en", en);
registerTranslation("es", es);

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: MaterialCommunityIcons;
}`;

const style = document.createElement("style");
style.type = "text/css";
if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
} else {
    style.appendChild(document.createTextNode(iconFontStyles));
}
document.head.appendChild(style);

require.context('./src/images', true, /\.(svg|png|jpe?g|gif)$/);

if (SingleSignOn.IsRedirect()) {
    SingleSignOn.Redirect();
} else {
    AppRegistry.registerComponent(AppJson.name, () => App);
    AppRegistry.runApplication(AppJson.name, {
        rootTag: document.getElementById('root'),
    });
}