"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const Theme_1 = require("../Theme");
const styles = react_native_1.StyleSheet.create({
    button: {
        alignItems: 'center',
        borderRadius: 4,
        flexDirection: 'row',
        justifyContent: 'center',
        userSelect: 'none'
    },
    buttonSolid: {
        padding: 8
    },
    buttonOutline: {
        borderWidth: 1,
        padding: 7
    },
    buttonText: {
        fontWeight: '500',
        textAlign: 'center'
    },
});
;
function Button(props) {
    let options = {
        color: props.color ?? Theme_1.THEME.Primary,
        disabled: props.disabled ?? false,
        onPress: props.onPress ?? (() => null),
        type: props.type ?? 'solid',
        textColor: props.textColor,
        textSize: props.textSize ?? 16,
        extraPadding: props.extraPadding
    };
    if (!options.textColor) {
        switch (options.type) {
            case 'solid':
                options.textColor = '#fff';
                break;
            case 'outline':
            case 'borderless':
                options.textColor = options.color;
                break;
        }
    }
    if (options.disabled) {
        options.color = '#00000033';
        switch (options.type) {
            case 'outline':
            case 'borderless':
                options.textColor = options.color;
                break;
        }
    }
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { accessible: true, "aria-label": props["aria-label"] ?? props.text ?? '', accessibilityHint: props.accessibilityHint ?? '', disabled: options.disabled, hitSlop: props.hitSlop, onPress: props.onPress, style: ({ pressed }) => [
            styles.button,
            (options.type == 'solid') ? styles.buttonSolid : null,
            (options.type == 'solid') ? { backgroundColor: options.color } : null,
            (options.type == 'outline') ? styles.buttonOutline : null,
            (options.type == 'outline') ? { borderColor: options.color } : null,
            (options.type == 'borderless') ? styles.buttonSolid : null,
            { pointerEvents: props.pointerEvents },
            props.style,
            pressed ? { opacity: 0.6 } : null
        ], children: props.text
            &&
                (0, jsx_runtime_1.jsx)(react_native_1.Text, { accessible: false, style: [
                        styles.buttonText,
                        {
                            margin: options.extraPadding,
                            color: options.textColor,
                            fontSize: options.textSize
                        },
                        !!props.textWeight ? { fontWeight: props.textWeight } : null
                    ], children: props.text })
            ||
                props.children }));
}
exports.default = Button;
;
