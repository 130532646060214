"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const react_native_1 = require("react-native");
const Theme_1 = require("../Theme");
const Helper_1 = require("../Helper");
const react_i18next_1 = require("react-i18next");
const isSizeWeb = react_native_1.Dimensions.get('window').width > 750;
const styles = react_native_1.StyleSheet.create({
    flex: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    fill: {
        alignItems: 'center',
        flex: 1
    },
    label: {
        backgroundColor: Theme_1.THEME.Secondary,
        borderRadius: 2,
        marginVertical: 4,
        paddingHorizontal: 6,
        paddingVertical: 2
    }
});
function PayStub(props) {
    const showIcon = props.showIcon ?? true;
    const allowPress = props.allowPress ?? true;
    const showYear = (props.showYear ?? true) && props.item.payDate && new Date(props.item.payDate).getFullYear() != new Date().getFullYear();
    const size = props.fontSize ?? (isSizeWeb ? 14 : 12);
    const { t } = (0, react_i18next_1.useTranslation)();
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { hitSlop: 4, disabled: !allowPress, onPress: props.onPress, style: ({ pressed }) => [Theme_1.STYLES.button, (pressed ? Theme_1.STYLES.pressed : null)], children: (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.flex, children: [(showIcon || props.label) &&
                    (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { flex: 2, alignItems: 'center' }, children: props.label
                            &&
                                (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.label, children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { fontSize: size - 2, color: '#fff', fontWeight: '500' }, children: props.label }) })
                            ||
                                (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: Theme_1.ICONS.Regular.MoneyCheckDollar, size: size + 14, color: Theme_1.THEME.Primary }) }), (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { flex: 8 }, children: (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.flex, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.fill, accessible: true, "aria-label": t('myPay:hints.date') + props.item?.payDate, children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { accessible: false, style: { fontSize: size + 2, color: Theme_1.THEME.Text, textAlign: 'center' }, children: props.item?.payDate ? new Date(props.item.payDate).toLocaleDateString(Helper_1.Helper.deviceLanguage(), { month: 'short', day: 'numeric', ...showYear ? { year: "numeric" } : null, timeZone: 'UTC' }) : "" }) }), (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.fill, accessible: true, "aria-label": t('myPay:hints.net') + Helper_1.Helper.FormatCurrency(props.item?.netPay), children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { accessible: false, style: { fontSize: size + 4, color: Theme_1.THEME.Text, fontWeight: '500' }, children: Helper_1.Helper.FormatCurrency(props.item?.netPay) }) })] }) }), allowPress &&
                    (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { flex: 1, alignItems: 'center' }, accessible: true, "aria-label": t('myPay:hints.stubDetail'), children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: Theme_1.ICONS.Regular.ChevronRight, size: size + 4, color: Theme_1.THEME.Text }) })] }) }));
}
exports.default = PayStub;
;
