"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
// Based on https://github.com/expo/expo-firebase-recaptcha
// Cleaned up. Removed expo dependency. Package not maintained.
const React = __importStar(require("react"));
const auth_1 = require("firebase/auth");
class Recaptcha extends React.Component {
    verifier = null;
    setRef = (ref) => {
        if (ref) {
            const auth = (0, auth_1.getAuth)();
            if (this.props.verificationDisabled !== undefined) {
                auth.settings.appVerificationDisabledForTesting =
                    !!this.props.verificationDisabled;
            }
            if (this.props.languageCode) {
                auth.languageCode = this.props.languageCode;
            }
            let options = {
                size: this.props.verificationInvisible ? 'invisible' : 'normal',
                action: this.props.action
            };
            this.verifier = new auth_1.RecaptchaVerifier(auth, ref, options);
        }
        else {
            this.verifier = null;
        }
        if (this.props.innerRef) {
            this.props.innerRef.current = this.verifier;
        }
    };
    shouldComponentUpdate(nextProps) {
        return (this.props.verificationDisabled !== nextProps.verificationDisabled ||
            this.props.verificationInvisible !== nextProps.verificationInvisible ||
            this.props.languageCode !== nextProps.languageCode);
    }
    componentDidUpdate(prevProps) {
        if (this.props.innerRef !== prevProps.innerRef) {
            if (this.props.innerRef) {
                this.props.innerRef.current = this.verifier;
            }
        }
    }
    render() {
        const { verificationInvisible, verificationDisabled, languageCode } = this.props;
        let key = (verificationInvisible ? 'invisible' : 'visible') + '-' +
            (verificationDisabled ? 'testing' : 'regular') + '-' +
            (languageCode ?? '');
        return ((0, jsx_runtime_1.jsx)("div", { style: { zIndex: 1000 }, id: "recaptcha-container", ref: this.setRef, dangerouslySetInnerHTML: { __html: '' } }, key));
    }
}
exports.default = React.forwardRef((props, ref) => ((0, jsx_runtime_1.jsx)(Recaptcha, { ...props, innerRef: ref })));
