"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const react_native_1 = require("react-native");
const react_i18next_1 = require("react-i18next");
const Theme_1 = require("../Theme");
const Helper_1 = require("../Helper");
const styles = react_native_1.StyleSheet.create({
    exemptLabel: {
        color: Theme_1.THEME.Text,
        backgroundColor: Theme_1.THEME.Warning,
        borderRadius: 2,
        paddingHorizontal: 4,
        paddingVertical: 2
    },
    flex: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    fill: {
        alignItems: 'center',
        flex: 1
    },
    label: {
        backgroundColor: Theme_1.THEME.Secondary,
        borderRadius: 2,
        marginVertical: 4,
        paddingHorizontal: 4,
        paddingVertical: 2
    }
});
function TaxInfo(props) {
    const { t } = (0, react_i18next_1.useTranslation)();
    const size = props.fontSize ?? (Helper_1.Helper.isSizeWeb ? 16 : 12);
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: props.onPress, style: Theme_1.STYLES.notSelectable, children: (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: [styles.flex, { paddingVertical: 12 }], children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: { flex: 2, alignItems: 'center' }, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: Theme_1.ICONS.Regular.ScaleBalanced, size: size + 14, color: Theme_1.THEME.Primary }) }), (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { flex: 3, alignItems: 'flex-start' }, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { marginLeft: 6 }, children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { fontSize: size + 2, color: Theme_1.THEME.Text, fontWeight: '500' }, children: props.label }) }) }), (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { flex: 5 }, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.flex, accessible: true, "aria-label": (props.exempt ? t('myPay:labels.exempt') : (!props.filingStatus ? t('myPay:labels.unknown') : props.filingStatus)), children: props.exempt
                            &&
                                (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.exemptLabel, accessible: true, "aria-label": t('myPay:labels.exempt'), children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { accessible: false, style: { fontSize: size - 1, color: Theme_1.THEME.Text, fontWeight: '500' }, children: t('myPay:labels.exempt').toUpperCase() }) })
                            ||
                                (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.fill, children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { accessible: false, style: { fontSize: size, color: Theme_1.THEME.Text, textAlign: 'center' }, children: (!props.filingStatus ? "—" : props.filingStatus) }) }) }) })] }) }));
}
exports.default = TaxInfo;
;
