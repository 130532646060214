"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Files = void 0;
class Files {
    static SaveToDownloads(name, mime, url) {
        var ref = global.window.open(url, '_blank');
        if (ref) {
            return Promise.resolve(null);
        }
        else {
            // Workaround for Safari to allow the popup.
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    ref = global.window.open(url, '_blank');
                    if (ref) {
                        resolve(null);
                    }
                    else {
                        reject(null);
                    }
                });
            });
        }
    }
}
exports.Files = Files;
