"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Theme_1 = require("../Theme");
const react_native_1 = require("react-native");
const styles = react_native_1.StyleSheet.create({
    button: {
        alignItems: 'center',
        backgroundColor: '#fff',
        borderColor: '#dadada',
        borderWidth: 1,
        borderBottomWidth: 2,
        borderRadius: 4,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 8
    },
    pressed: {
        backgroundColor: Theme_1.THEME.Secondary + '11',
        borderColor: Theme_1.THEME.Secondary + 'aa'
    }
});
function MainMenuButton(props) {
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { accessible: true, "aria-label": props["aria-label"], accessibilityHint: props.accessibilityHint, onPress: props.disabled ? (() => null) : props.onPress, style: ({ pressed }) => [
            styles.button,
            props.style,
            ((pressed && !props.disabled) ? (props.pressedStyle ?? styles.pressed) : null)
        ], children: props.children }));
}
exports.default = MainMenuButton;
