"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const Helper_1 = require("../Helper");
function TableHeader(props) {
    return ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: [
            {
                flexDirection: 'row',
                justifyContent: 'center',
                paddingVertical: 4,
                backgroundColor: '#f4f4f4',
                borderRadius: 2
            },
            props.style
        ], children: props.columns != null &&
            props.columns.length > 0 &&
            props.columns.map((c, i) => (0, jsx_runtime_1.jsx)(react_native_1.View, { style: [
                    {
                        justifyContent: 'center',
                        flex: c.flex,
                        alignItems: c.align ?? 'center',
                    },
                    c.viewStyle
                ], children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { "aria-label": c.accLabel ?? c.label, style: [
                        {
                            fontSize: Helper_1.Helper.isSizeWeb ? 14 : 12,
                            fontWeight: '500',
                            textAlign: c.align == 'center' ? 'center' : null,
                            color: '#555',
                            marginLeft: c.align == 'flex-end' ? 0 : (c.indent ?? 0),
                            marginRight: c.align == 'flex-end' ? (c.indent ?? 0) : 0
                        },
                        c.textStyle
                    ], children: c.label }) }, i)) }));
}
exports.default = TableHeader;
;
